import { userAxios } from '../index'

const checkProcedure = async (data) => {
    try {
        return await userAxios.post(`office_visit/check_procedure`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const getRecipe = async (appointmentId) => {
    try {
        return await userAxios.get(`appointment/recipe/${appointmentId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    checkProcedure,
    getRecipe
}